@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Avenir Next', 'Helvetica Neue', Arial, sans-serif;
  overflow-y: scroll;
  @apply bg-amber-50;
}

img,
svg {
  max-width: 100%;
  height: auto;
}
